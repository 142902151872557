<template>
  <Page>
    <div class="home-page">
      <zui-header />
      <div class="user-box">
        <div class="left-box">
          <div class="info-box">
            <template v-if="userInfo">
              <h1>
                {{ $t(wecomeText)
                }}{{ userInfo.nickName ? "," + userInfo.nickName : "" }} {{this.$i18n.locale === 'zh' ? "。": "."}}
              </h1>
              <div>
                ID:{{ userInfo.nickName ? userInfo.nickName : "" }}
                <el-link
                  type="primary"
                  :underline="false"
                  style="margin-left: 10px"
                  @click="$router.push({ name: 'VerifyIDPage' })"
                  >{{ $t('user_info.edit') }}
                </el-link>
              </div>
              <div>
                {{ $t('user_info.email') }}
                <span v-if="userInfo.mailbox">{{ userInfo.mailbox }}</span>
                <span v-else
                  >  {{ $t('user_info.un_verification') }}
                  <el-link
                    type="primary"
                    :underline="false"
                    @click="$router.push({ name: 'verifyEmailPage' })"
                    >{{ $t('user_info.edit_email') }}</el-link
                  ></span
                >
              </div>
              <div>
                {{ $t('user_info.phone') }}
                <span v-if="userInfo.mobile">{{ userInfo.mobile }}</span>
                <span v-else
                  > {{ $t('user_info.un_verification') }}
                  <el-link
                    type="primary"
                    :underline="false"
                    style="margin-left: 10px"
                    @click="$router.push({ name: 'VerifyPhonePage' })"
                    >{{ $t('user_info.edit') }}</el-link
                  ></span
                >
              </div>
              <div>
                <el-link
                  type="primary"
                  :underline="false"
                  @click="$router.push({ name: 'changePsw' })"
                  > {{ $t('user_info.edit_password') }}</el-link
                >
              </div>
            </template>
            <template v-else>
              <el-link
                :underline="false"
                @click="$router.push({ name: 'LoginPage' })"
                >{{ $t('user_info.to_login') }}</el-link
              >
            </template>
          </div>
          <div class="bg-box">
            <img src="https://www.achencybe.com/img/pc/user/bg.png" />
          </div>
        </div>
        <template v-if="orderList.length">
          <div class="right-box">
            <div class="left-nav">
              <h2>{{ $t('user_info.latest_order') }}</h2>
              <el-tabs
                tab-position="left"
                style="height: 700px"
                v-model="activeName"
              >
                <el-tab-pane
                  v-for="item in orderList"
                  :key="item.id"
                  :label="item.timer"
                  :name="item.id"
                >
                  <div slot="label" class="tabs-title" v-if="$i18n.locale === 'zh'">
                    <div>{{ item.year }}</div>
                    <div>{{ item.timer }}</div>
                  </div>
                  <div slot="label" class="tabs-title" v-else>
                    <div>{{ item.timer }}{{ item.year }} </div>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
            <div class="order-box">
              <div>
                <div class="title-box">
                  <div v-for="item in order.title" :key="item">{{ item }}</div>
                </div>
                <ul>
                  <li v-for="item in order.list" :key="item.partsName">
                    {{ item.partsName + "*" + item.partsNumber }}
                  </li>
                </ul>
                <h1 style="font-size: 24px">
                  {{ $t('user_info.estimate_arrive') }}
                  <span style="margin-left: 10px">{{ order.arriveTime }}</span>
                </h1>
                <div class="progress-box">
                  <span
                    v-show="order.state !== ''"
                    :style="{ width: 20 * (order.state + 1) + '%' }"
                  ></span>
                </div>
                <div class="progress-text">
                  <span
                    v-for="(item, idx) in stateList"
                    :key="idx"
                    :class="{ active: order.state === idx }"
                    >{{ $t(item) }}</span
                  >
                </div>
                <div class="logistics">
                  <div>
                    {{ $t('user_info.logistics') }}{{
                      order.logisticsName ? order.logisticsName : $t('user_info.un_generated')
                    }}
                  </div>
                  <div>
                    {{ $t('user_info.commodity_num') }}{{
                      order.logisticsId ? order.logisticsId : $t('user_info.un_generated')
                    }}
                  </div>
                  <!--                  <el-scrollbar style="height:160px;">-->
                  <!--                    <div class="logistics-box">-->
                  <!--                      <div class="line"></div>-->
                  <!--                      <div v-for="item in order.logisticsList" :key="item.time" class="logistics-item">-->
                  <!--                        <div class="time">{{ item.time }}</div>-->
                  <!--                        <div>{{ item.content }}</div>-->
                  <!--                      </div>-->
                  <!--                    </div>-->

                  <!--                  </el-scrollbar>-->
                </div>

                <div class="pay-box">
                  <div>{{ $t('user_info.pay_summary') }}</div>
                  <div class="pay-type">
                    {{ $t('user_info.pay_type_title') }}<img
                      :src="
                        'https://www.achencybe.com/img/pc/user/' +
                        payImg +
                        '.png'
                      "
                    />
                    <!-- {{
                      order.payType == 1 ? "全款" : "贷款"
                    }} -->
                  </div>
                  <div>{{ $t('user_info.freight') }} {{ order.freight ? order.freight : $t('user_info.free') }}</div>
                  <h2>
                    {{ $t('user_info.total')  }}{{payUnit}}
                    <span style="font-family: 'ELEMENT-ICONS'">{{
                      formatPrice(order.orderTotal)
                    }}</span>
                  </h2>
                  <template v-if="order.orderType == '1'">
                    <div class="tips">
                      <template v-if="order.isPaymentNode == '0'">
                        {{ $t('user_info.title_1')  }}
                      </template>
                      <template v-else>
                        {{ $t('user_info.deposit_begin')  }}{{ order.dilatationCoefficient }} {{ $t('user_info.deposit_end')  }}
                      </template>
                    </div>
                    <el-button
                      class="pay-btn"
                      type="primary"
                      v-if="order.isPayment == '1'"
                      :disabled="order.isPaymentNode == '0'"
                      @click="pay"
                      >{{ $t('user_info.pay_balance')  }}</el-button
                    >
                  </template>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="right-box" style="padding: 0">
            <span class="empty">{{ $t('user_info.no_order')  }}</span>
            <img
              src="https://www.achencybe.com/img/pc/user/order_bg.png"
              style="width: 100%; height: 100%"
            />
          </div>
        </template>
      </div>
      <zui-footer />
    </div>
  </Page>
</template>
<script>
import "../../less/home-page.less";
import Page from "../../../components/Page";
import ZuiHeader from "../../../components/Header";
import ZuiFooter from "../../../components/Footer";

export default {
  components: { Page, ZuiHeader, ZuiFooter },
  data() {
    return {
      // userInfo: {
      //   name: "Mark",
      //   email: "450753268@qq.com",
      //   phone: "",
      // },

      stateList: ["user_info.state_list.state_1", "user_info.state_list.state_2", "user_info.state_list.state_3", "user_info.state_list.state_4", "user_info.state_list.state_5"],
      activeName: "0",
      orderList: [],
      order: {},
    };
  },
  watch: {
    activeName() {
      this.getOrder();
    },
    userInfo: {
      handler(userInfo) {
        this.getOrderList();
      },
      immediate: true,
      deep: true,
    },
    language: {
      handler(language) {
        this.getOrderList();
        this.getOrder();
      }
    },
  },
  computed: {
    payImg(){
      const payWay = this.order.payWay;
      const img = [
          "wx",
          "zfb",
          "paypal"
      ]
      return img[payWay]
    },
    payUnit(){
      return this.order.payWay === '2' ? "$" : "RMB"
    },
    wecomeText() {
      // 获取当前时间
      let timeNow = new Date();
      // 获取当前小时
      let hours = timeNow.getHours();
      // 设置默认文字
      let state = ``;
      // 判断当前时间段
      if (hours >= 0 && hours <= 10) {
        state = `user_info.welcome_title.morning`;
      } else if (hours > 10 && hours <= 14) {
        state = `user_info.welcome_title.noon`;
      } else if (hours > 14 && hours <= 18) {
        state = `user_info.welcome_title.afternoon`;
      } else if (hours > 18 && hours <= 24) {
        state = `user_info.welcome_title.evening`;
      }
      return state;
    },
    userInfo() {
      console.log(this.$store.state.userInfo)
      return this.$store.state.userInfo;
    },
    language(){
      return this.$store.state.language;
    },
  },
  mounted(){
    if (!localStorage.getItem("ticket")) {
      window.sessionStorage.setItem("isCompletion", JSON.stringify(this.$route.query));
      this.$router.push({ name: "LoginPage" });
    }
  },
  methods: {
    getOrderList() {
      if (!this.userInfo || !this.userInfo.id) {
        return;
      }
      this.$api
          .getOrderList({
            clientId: this.userInfo.id,
          })
          .then((res) => {
            if (res.code === 0) {
              this.orderList = res.data.map((item) => {
                return {
                  id: item.id,
                  year: this.$timeFormat(item.creationTime, this.$t('user_info.years')),
                  timer: this.$timeFormat(item.creationTime, this.$t('user_info.day')),
                };
              });
              if (this.orderList.length) {
                this.activeName = this.orderList[0].id;
              }
            }
          });
    },
    getOrder() {
      if (!this.activeName) return;
      this.$api
          .getOrder({
            orderId: this.activeName,
          })
          .then((res) => {
            if (res.code === 0) {
              const changeNumToHan = (num) => {
                if (!num) return;
                var arr1 = [
                  "零",
                  "一",
                  "双",
                  "三",
                  "四",
                  "五",
                  "六",
                  "七",
                  "八",
                  "九",
                ];
                var arr2 = [
                  "",
                  "十",
                  "百",
                  "千",
                  "万",
                  "十",
                  "百",
                  "千",
                  "亿",
                  "十",
                  "百",
                  "千",
                  "万",
                  "十",
                  "百",
                  "千",
                  "亿",
                ];
                if (!num || isNaN(num)) return "零";
                var english = num.toString().split("");
                var result = "";
                for (var i = 0; i < english.length; i++) {
                  var des_i = english.length - 1 - i; // 倒序排列设值
                  result = arr2[i] + result;
                  var arr1_index = english[des_i];
                  result = arr1[arr1_index] + result;
                }
                result = result
                    .replace(/零(千|百|十)/g, "零")
                    .replace(/十零/g, "十"); // 将【零千、零百】换成【零】 【十零】换成【十】
                result = result.replace(/零+/g, "零"); // 合并中间多个零为一个零
                result = result.replace(/零亿/g, "亿").replace(/零万/g, "万"); // 将【零亿】换成【亿】【零万】换成【万】
                result = result.replace(/亿万/g, "亿"); // 将【亿万】换成【亿】
                result = result.replace(/零+$/, ""); // 移除末尾的零
                // 将【一十】换成【十】
                result = result.replace(/^一十/g, "十");
                return result;
              };
              this.order = {
                id: res.data.id,
                orderId: res.data.orderNumber,
                year: this.$timeFormat(res.data.creationTime, this.$t('user_info.years')),
                timer: this.$timeFormat(res.data.creationTime, this.$t('user_info.day') ),
                title: [
                  this.$t('user_info.title_2'),
                  `For ${res.data.listOrderGoodsMountings[0].brandName} ${res.data.listOrderGoodsMountings[0].carModelName}`,
                ],
                list: res.data.listOrderGoodsMountings,
                arriveTime: this.$timeFormat(
                    res.data.completionTime,
                    this.$t('user_info.year_day')
                ),
                state:
                    res.data.orderState !== "0"
                        ? Number(res.data.orderState) - 1
                        : "",
                logisticsName: res.data.deliveryBusinessName,
                logisticsId: res.data.courierNumber,
                logisticsList: [],
                payWay: res.data.patternOfPayment, //付款方式
                // payType: res.data.patternOfPayment, //付款类型
                freight: 0, //运费
                orderTotal: res.data.orderTotalAmount, //总额
                orderType: res.data.orderType,
                isPayment: res.data.isPayment,
                isPaymentNode: res.data.isPaymentNode,
                dilatationCoefficient: changeNumToHan(
                    res.data.dilatationCoefficient
                ),
              };
            }
          });
    },
    formatPrice(val) {
      if (!val) return;
      return val.toString().replace(/\d+/, function (n) {
        return n.replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      });
    },

    pay() {
      this.$router.push({
        name:"PaymentPage",
        query:{
          order:this.order.orderId,
          total:this.order.orderTotal,
        }
      })
      // this.$api
      //   .updateOrder({
      //     orderNumber: this.order.orderId,
      //   })
      //   .then((res) => {
      //     this.getOrder();
      //   });
    },
  },
};
</script>

<style lang="scss" scoped>
.user-box {
  margin: 120px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .left-box {
    height: 843px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    img {
      display: block;
    }
    .info-box {
      box-sizing: border-box;
      width: 564px;
      height: 268px;
      background-color: #fff;
      padding: 40px 65px 26px;
      text-align: left;
      font-size: 14px;

      h1 {
        font-size: 32px;
        margin-bottom: 20px;
        font-weight: 500;
      }

      div {
        margin-top: 4px;

        .el-link {
          margin-top: -2px;
        }
      }
    }
  }

  .right-box {
    width: 846px;
    height: 843px;
    background-color: #fff;
    margin-left: 18px;
    box-sizing: border-box;
    padding: 24px 0;
    display: flex;
    position: relative;
    .empty {
      position: absolute;
      top: 48px;
      left: 71px;
      font-size: 19px;
      color: #fff;
    }
    .left-nav {
      width: 170px;
      height: 100%;
      border-right: 1px solid #656565;
      box-sizing: border-box;

      h2 {
        font-size: 18px;
        width: 100%;
        font-weight: initial;
        text-align: center;
        padding: 30px 34px;
      }
      ::v-deep .el-tabs__nav-wrap {
        padding: 0;
      }
      ::v-deep .el-tabs__nav-scroll {
        overflow: hidden auto;
      }
      ::v-deep  .el-tabs__header {
        width: 100%;

        .el-tabs__item {
          text-align: left;
          padding: 0 24px;
          height: 60px;
        }
        .el-tabs__active-bar {
          display: none;
        }
      }
      .tabs-title {
        margin: 0 auto;
        width: 90px;
        padding: 4px 6px;
        bvorder: 1px solid #409eff;
        border-radius: 6px;
        div {
          height: 18px;
          line-height: 18px;
        }
      }
      .is-active .tabs-title {
        background-color: #409eff;
        div {
          color: #fff !important;
        }
      }
    }

    .order-box {
      margin-left: 64px;
      width: 535px;
      text-align: left;
      font-weight: bolder;
      line-height: 1.6;

      .title-box {
        padding-top: 30px;
      }

      ul {
        margin-top: 30px;
        margin-left: 20px;
        margin-bottom: 50px;
        list-style: disc;
        font-weight: initial;
        white-space: pre-line;
      }

      h1 {
        font-weight: initial;
      }

      h2 {
        font-weight: bolder;
      }

      .progress-box {
        width: 100%;
        height: 8px;
        background-color: #c8c8c8;
        border-radius: 10px;
        margin: 10px 0 10px;
        position: relative;

        span {
          width: 0;
          position: absolute;
          display: inline-block;
          height: 8px;
          background-color: #007f00;
          top: 0;
          left: 0;
          transition: width 0.25s;
          border-radius: 10px;
        }
      }

      .progress-text {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #97979a;
        font-size: 13px;
        font-weight: 300;

        & > .active {
          color: #000;
        }
      }

      .logistics {
        margin-top: 30px;
        font-weight: 300;

        //div {
        //  margin-top: 4px;
        //}

        .logistics-box {
          margin: 0 20px;
          position: relative;
          padding: 10px 20px;
        }

        .line {
          position: absolute;
          background-color: #ececec;
          width: 2px;
          height: 100%;
          left: 0;
          top: 0;
        }

        .logistics-item + .logistics-item {
          border-top: 1px solid #ececec;
          padding: 6px 0;
        }

        .logistics-item {
          font-weight: initial;

          .time {
            position: relative;

            &::before {
              position: absolute;
              content: " ";
              width: 6px;
              height: 6px;
              border-radius: 10px;
              background-color: #afc6ea;
              left: -22px;
              top: 10px;
              z-index: 3;
            }

            &::after {
              position: absolute;
              content: " ";
              width: 16px;
              height: 16px;
              border-radius: 12px;
              background-color: #fff;
              left: -22px;
              top: 6px;
              z-index: 2;
            }
          }
        }
      }

      .pay-box {
        margin-top: 50px;
        font-weight: 300;

        .pay-type {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          margin-top: -6px;
          img{
            width:100px;
          }
        }
        .tips {
          font-size: 14px;
          color: #adadad;
          font-weight: 100;
          margin-top: -4px;
          margin-bottom: 6px;
        }
        .pay-btn {
          padding: 8px 32px;
          background-color: #409eff;
          &.is-disabled {
            background-color: #9c9c9c;
            border-color: #9c9c9c;
          }
        }
      }
    }
  }
}
</style>
