var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Page", [
    _c(
      "div",
      { staticClass: "home-page" },
      [
        _c("zui-header"),
        _c(
          "div",
          { staticClass: "user-box" },
          [
            _c("div", { staticClass: "left-box" }, [
              _c(
                "div",
                { staticClass: "info-box" },
                [
                  _vm.userInfo
                    ? [
                        _c("h1", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t(_vm.wecomeText)) +
                              _vm._s(
                                _vm.userInfo.nickName
                                  ? "," + _vm.userInfo.nickName
                                  : ""
                              ) +
                              " " +
                              _vm._s(this.$i18n.locale === "zh" ? "。" : ".") +
                              " "
                          ),
                        ]),
                        _c(
                          "div",
                          [
                            _vm._v(
                              " ID:" +
                                _vm._s(
                                  _vm.userInfo.nickName
                                    ? _vm.userInfo.nickName
                                    : ""
                                ) +
                                " "
                            ),
                            _c(
                              "el-link",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: { type: "primary", underline: false },
                                on: {
                                  click: function ($event) {
                                    return _vm.$router.push({
                                      name: "VerifyIDPage",
                                    })
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("user_info.edit")) + " ")]
                            ),
                          ],
                          1
                        ),
                        _c("div", [
                          _vm._v(" " + _vm._s(_vm.$t("user_info.email")) + " "),
                          _vm.userInfo.mailbox
                            ? _c("span", [_vm._v(_vm._s(_vm.userInfo.mailbox))])
                            : _c(
                                "span",
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("user_info.un_verification")
                                      ) +
                                      " "
                                  ),
                                  _c(
                                    "el-link",
                                    {
                                      attrs: {
                                        type: "primary",
                                        underline: false,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$router.push({
                                            name: "verifyEmailPage",
                                          })
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("user_info.edit_email"))
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                        ]),
                        _c("div", [
                          _vm._v(" " + _vm._s(_vm.$t("user_info.phone")) + " "),
                          _vm.userInfo.mobile
                            ? _c("span", [_vm._v(_vm._s(_vm.userInfo.mobile))])
                            : _c(
                                "span",
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("user_info.un_verification")
                                      ) +
                                      " "
                                  ),
                                  _c(
                                    "el-link",
                                    {
                                      staticStyle: { "margin-left": "10px" },
                                      attrs: {
                                        type: "primary",
                                        underline: false,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$router.push({
                                            name: "VerifyPhonePage",
                                          })
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("user_info.edit")))]
                                  ),
                                ],
                                1
                              ),
                        ]),
                        _c(
                          "div",
                          [
                            _c(
                              "el-link",
                              {
                                attrs: { type: "primary", underline: false },
                                on: {
                                  click: function ($event) {
                                    return _vm.$router.push({
                                      name: "changePsw",
                                    })
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("user_info.edit_password"))
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    : [
                        _c(
                          "el-link",
                          {
                            attrs: { underline: false },
                            on: {
                              click: function ($event) {
                                return _vm.$router.push({ name: "LoginPage" })
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("user_info.to_login")))]
                        ),
                      ],
                ],
                2
              ),
              _c("div", { staticClass: "bg-box" }, [
                _c("img", {
                  attrs: {
                    src: "https://www.achencybe.com/img/pc/user/bg.png",
                  },
                }),
              ]),
            ]),
            _vm.orderList.length
              ? [
                  _c("div", { staticClass: "right-box" }, [
                    _c(
                      "div",
                      { staticClass: "left-nav" },
                      [
                        _c("h2", [
                          _vm._v(_vm._s(_vm.$t("user_info.latest_order"))),
                        ]),
                        _c(
                          "el-tabs",
                          {
                            staticStyle: { height: "700px" },
                            attrs: { "tab-position": "left" },
                            model: {
                              value: _vm.activeName,
                              callback: function ($$v) {
                                _vm.activeName = $$v
                              },
                              expression: "activeName",
                            },
                          },
                          _vm._l(_vm.orderList, function (item) {
                            return _c(
                              "el-tab-pane",
                              {
                                key: item.id,
                                attrs: { label: item.timer, name: item.id },
                              },
                              [
                                _vm.$i18n.locale === "zh"
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "tabs-title",
                                        attrs: { slot: "label" },
                                        slot: "label",
                                      },
                                      [
                                        _c("div", [_vm._v(_vm._s(item.year))]),
                                        _c("div", [_vm._v(_vm._s(item.timer))]),
                                      ]
                                    )
                                  : _c(
                                      "div",
                                      {
                                        staticClass: "tabs-title",
                                        attrs: { slot: "label" },
                                        slot: "label",
                                      },
                                      [
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(item.timer) +
                                              _vm._s(item.year) +
                                              " "
                                          ),
                                        ]),
                                      ]
                                    ),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "order-box" }, [
                      _c("div", [
                        _c(
                          "div",
                          { staticClass: "title-box" },
                          _vm._l(_vm.order.title, function (item) {
                            return _c("div", { key: item }, [
                              _vm._v(_vm._s(item)),
                            ])
                          }),
                          0
                        ),
                        _c(
                          "ul",
                          _vm._l(_vm.order.list, function (item) {
                            return _c("li", { key: item.partsName }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    item.partsName + "*" + item.partsNumber
                                  ) +
                                  " "
                              ),
                            ])
                          }),
                          0
                        ),
                        _c("h1", { staticStyle: { "font-size": "24px" } }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("user_info.estimate_arrive")) +
                              " "
                          ),
                          _c(
                            "span",
                            { staticStyle: { "margin-left": "10px" } },
                            [_vm._v(_vm._s(_vm.order.arriveTime))]
                          ),
                        ]),
                        _c("div", { staticClass: "progress-box" }, [
                          _c("span", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.order.state !== "",
                                expression: "order.state !== ''",
                              },
                            ],
                            style: { width: 20 * (_vm.order.state + 1) + "%" },
                          }),
                        ]),
                        _c(
                          "div",
                          { staticClass: "progress-text" },
                          _vm._l(_vm.stateList, function (item, idx) {
                            return _c(
                              "span",
                              {
                                key: idx,
                                class: { active: _vm.order.state === idx },
                              },
                              [_vm._v(_vm._s(_vm.$t(item)))]
                            )
                          }),
                          0
                        ),
                        _c("div", { staticClass: "logistics" }, [
                          _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("user_info.logistics")) +
                                _vm._s(
                                  _vm.order.logisticsName
                                    ? _vm.order.logisticsName
                                    : _vm.$t("user_info.un_generated")
                                ) +
                                " "
                            ),
                          ]),
                          _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("user_info.commodity_num")) +
                                _vm._s(
                                  _vm.order.logisticsId
                                    ? _vm.order.logisticsId
                                    : _vm.$t("user_info.un_generated")
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "pay-box" },
                          [
                            _c("div", [
                              _vm._v(_vm._s(_vm.$t("user_info.pay_summary"))),
                            ]),
                            _c("div", { staticClass: "pay-type" }, [
                              _vm._v(
                                " " + _vm._s(_vm.$t("user_info.pay_type_title"))
                              ),
                              _c("img", {
                                attrs: {
                                  src:
                                    "https://www.achencybe.com/img/pc/user/" +
                                    _vm.payImg +
                                    ".png",
                                },
                              }),
                            ]),
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm.$t("user_info.freight")) +
                                  " " +
                                  _vm._s(
                                    _vm.order.freight
                                      ? _vm.order.freight
                                      : _vm.$t("user_info.free")
                                  )
                              ),
                            ]),
                            _c("h2", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("user_info.total")) +
                                  _vm._s(_vm.payUnit) +
                                  " "
                              ),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-family": "'ELEMENT-ICONS'",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatPrice(_vm.order.orderTotal)
                                    )
                                  ),
                                ]
                              ),
                            ]),
                            _vm.order.orderType == "1"
                              ? [
                                  _c(
                                    "div",
                                    { staticClass: "tips" },
                                    [
                                      _vm.order.isPaymentNode == "0"
                                        ? [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("user_info.title_1")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        : [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "user_info.deposit_begin"
                                                  )
                                                ) +
                                                _vm._s(
                                                  _vm.order
                                                    .dilatationCoefficient
                                                ) +
                                                " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "user_info.deposit_end"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ],
                                    ],
                                    2
                                  ),
                                  _vm.order.isPayment == "1"
                                    ? _c(
                                        "el-button",
                                        {
                                          staticClass: "pay-btn",
                                          attrs: {
                                            type: "primary",
                                            disabled:
                                              _vm.order.isPaymentNode == "0",
                                          },
                                          on: { click: _vm.pay },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("user_info.pay_balance")
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]),
                    ]),
                  ]),
                ]
              : [
                  _c(
                    "div",
                    { staticClass: "right-box", staticStyle: { padding: "0" } },
                    [
                      _c("span", { staticClass: "empty" }, [
                        _vm._v(_vm._s(_vm.$t("user_info.no_order"))),
                      ]),
                      _c("img", {
                        staticStyle: { width: "100%", height: "100%" },
                        attrs: {
                          src: "https://www.achencybe.com/img/pc/user/order_bg.png",
                        },
                      }),
                    ]
                  ),
                ],
          ],
          2
        ),
        _c("zui-footer"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }